<template>
	<main class="container-wide">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Know Your Customer</h1>
			</div>

			<a
				href="#"
				@click.prevent="$refs.fileInput.click()"
				class="btn-add"
			></a>

			<input
				ref="fileInput"
				class="display-none"
				type="file"
				accept="text/csv"
				@change="fileUploaded"
			/>
		</div>

		<!-- CSV Overlay -->
		<VOverlay
			:visible="!!data.length"
			@close="
				data = [];
				confirmCustomers = [];
			"
		>
			<div class="mb-20">
				<h1 class="mb-10">Vátta v-tøl</h1>

				<p class="color-grey mb-10">Vátta, at v-tølini, sum eru á skerminum, eru røtt.</p>

				<p
					class="color-grey"
					style="font-size: 11px"
				>
					<strong>GG</strong>: Hetta kann taka eina løtu, um talan er um nógvar avtalur.
				</p>
			</div>

			<div
				class="mb-20 color-grey-600"
				style="max-height: 320px; overflow-y: auto"
			>
				<template v-if="!confirmCustomers.length">
					<div
						v-for="(entry, index) in data"
						:key="index"
						class="vtal"
					>
						{{ entry[0] }}

						<i
							class="fas fa-trash-alt hover:color-red cursor-pointer"
							@click="removeVtalAtIndex(index)"
						></i>
					</div>
				</template>

				<template v-if="confirmCustomers.length">
					<div
						v-for="(customer, index) in confirmCustomers"
						:key="index"
						class="vtal"
					>
						<div :class="{ 'color-red line-through': !customer }">
							{{ customer ? customer.name : data[index][0] }}
						</div>

						<i
							class="fas fa-trash-alt hover:color-red cursor-pointer"
							@click="removeCustomerAtIndex(index)"
						></i>
					</div>
				</template>
			</div>

			<div class="flex space-between">
				<div
					@click="checkVtol"
					class="btn inline-block"
					:class="{ disabled: overlayLoading }"
				>
					Kanna
				</div>

				<div
					@click="confirmVtol"
					class="btn inline-flex"
					:class="{ disabled: !vtolOK }"
				>
					<div
						v-if="overlayLoading"
						class="flex justify-center align-center"
					>
						<i class="fas fa-spinner fa-spin mr-5"></i>
					</div>

					Stovna
				</div>
			</div>
		</VOverlay>

		<template v-if="loading">
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</template>

		<table v-if="!!incompleteAgreements.length">
			<thead>
				<th class="whitespace-nowrap">ID</th>

				<th class="whitespace-nowrap">V-tal</th>

				<th class="whitespace-nowrap">Kundi</th>

				<th class="whitespace-nowrap">Stig</th>

				<th class="whitespace-nowrap">Seinast dagført</th>

				<th class="whitespace-nowrap text-center">Bankaváttan</th>

				<th class="whitespace-nowrap text-center">Undirskrivað</th>

				<th class="whitespace-nowrap text-center">Sent til Swedbank</th>

				<th></th>
			</thead>

			<tbody>
				<tr
					class="relative"
					v-for="agreement in incompleteAgreements"
					:key="`agreement-${agreement.id}`"
				>
					<td>
						{{ agreement.data ? agreement.data.kycId : '-' }}
					</td>

					<td>
						{{ getCustomerVTal(agreement) }}
					</td>

					<td>
						<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }">
							{{ getCustomerName(agreement) }}
						</router-link>
					</td>

					<td>{{ getSteps(agreement)[0] }} / {{ getSteps(agreement)[1] }}</td>

					<td v-if="isApproved(agreement)">
						<router-link
							:to="{ name: 'AgreementShow', params: { id: agreement.id } }"
							class="color-green"
						>
							Góðkend
						</router-link>
					</td>

					<td v-else-if="isSentForApproval(agreement)">
						<router-link
							:to="{ name: 'AgreementShow', params: { id: agreement.id } }"
							class="color-blue"
						>
							Góðkenning umbiðin
						</router-link>
					</td>

					<td
						v-else
						:class="{ 'color-red': isInactive(agreement) }"
					>
						<span>{{ getUpdatedAt(agreement) }}</span>
					</td>

					<td class="text-center">
						<i
							v-if="hasBankVerification(agreement)"
							title="Fingið bankaváttan"
							class="fas fa-check-circle color-green"
						></i>

						<div
							v-else
							class="color-grey-200"
						>
							-
						</div>
					</td>

					<td class="text-center">
						<i
							v-if="hasSignature(agreement)"
							title="Fingið undirskrift"
							class="fas fa-check-circle color-green"
						></i>

						<div
							v-else
							class="color-grey-200"
						>
							-
						</div>
					</td>

					<td class="text-center">
						<input
							type="checkbox"
							v-model="agreement.sentToSwedbankAt"
							@click="toggleSentToSwedbank(agreement)"
						/>
					</td>

					<td class="right">
						<div class="flex justify-end">
							<div
								:title="
									agreement.agreementLink && agreement.agreementLink.numberOfVisits
										? 'Kundin hevur sæð hetta'
										: 'Kundin hevur ikki sæð hetta enn'
								"
								class="mr-10"
							>
								<i
									class="fas fa-eye"
									:class="{
										'color-blue': agreement.agreementLink && agreement.agreementLink.numberOfVisits,
										'color-grey-300': agreement.agreementLink && !agreement.agreementLink.numberOfVisits,
									}"
								></i>
							</div>

							<a
								v-if="getAgreementLink(agreement)"
								:href="getAgreementLink(agreement)"
								target="_blank"
								class="color-green mr-10"
							>
								<i class="fas fa-link"></i>
							</a>

							<a
								href="#"
								@click.prevent="archiveAgreement(agreement)"
								class="color-red"
							>
								<i class="fas fa-archive"></i>
							</a>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<template v-if="!!completedAgreements.length">
			<h2 class="mt-50 mb-25">Sent til Swedbank</h2>

			<table>
				<thead>
					<th class="whitespace-nowrap">ID</th>

					<th class="whitespace-nowrap">V-tal</th>

					<th class="whitespace-nowrap">Kundi</th>

					<th class="whitespace-nowrap">Stig</th>

					<th class="whitespace-nowrap">Seinast dagført</th>

					<th class="whitespace-nowrap text-center">Bankaváttan</th>

					<th class="whitespace-nowrap text-center">Undirskrivað</th>

					<th class="whitespace-nowrap text-center">Sent til Swedbank</th>

					<th></th>
				</thead>

				<tbody>
					<tr
						class="relative"
						v-for="agreement in completedAgreements"
						:key="`agreement-${agreement.id}`"
					>
						<td>
							{{ agreement.data ? agreement.data.kycId : '-' }}
						</td>

						<td>
							{{ getCustomerVTal(agreement) }}
						</td>

						<td>
							<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }">
								{{ getCustomerName(agreement) }}
							</router-link>
						</td>

						<td>{{ getSteps(agreement)[0] }} / {{ getSteps(agreement)[1] }}</td>

						<td v-if="isApproved(agreement)">
							<router-link
								:to="{ name: 'AgreementShow', params: { id: agreement.id } }"
								class="color-green"
							>
								Góðkend
							</router-link>
						</td>

						<td v-else-if="isSentForApproval(agreement)">
							<router-link
								:to="{ name: 'AgreementShow', params: { id: agreement.id } }"
								class="color-blue"
							>
								Góðkenning umbiðin
							</router-link>
						</td>

						<td
							v-else
							:class="{ 'color-red': isInactive(agreement) }"
						>
							<span>{{ getUpdatedAt(agreement) }}</span>
						</td>

						<td class="text-center">
							<i
								v-if="hasBankVerification(agreement)"
								title="Fingið bankaváttan"
								class="fas fa-check-circle color-green"
							></i>

							<div
								v-else
								class="color-grey-200"
							>
								-
							</div>
						</td>

						<td class="text-center">
							<i
								v-if="hasSignature(agreement)"
								title="Fingið undirskrift"
								class="fas fa-check-circle color-green"
							></i>

							<div
								v-else
								class="color-grey-200"
							>
								-
							</div>
						</td>

						<td class="text-center">
							<input
								type="checkbox"
								v-model="agreement.sentToSwedbankAt"
								@click="toggleSentToSwedbank(agreement)"
							/>
						</td>

						<td class="right">
							<div class="flex justify-end">
								<div
									:title="
										agreement.agreementLink && agreement.agreementLink.numberOfVisits
											? 'Kundin hevur sæð hetta'
											: 'Kundin hevur ikki sæð hetta enn'
									"
									class="mr-10"
								>
									<i
										class="fas fa-eye"
										:class="{
											'color-blue': agreement.agreementLink && agreement.agreementLink.numberOfVisits,
											'color-grey-300': agreement.agreementLink && !agreement.agreementLink.numberOfVisits,
										}"
									></i>
								</div>

								<a
									v-if="getAgreementLink(agreement)"
									:href="getAgreementLink(agreement)"
									target="_blank"
									class="color-green mr-10"
								>
									<i class="fas fa-link"></i>
								</a>

								<a
									href="#"
									@click.prevent="archiveAgreement(agreement)"
									class="color-red"
								>
									<i class="fas fa-archive"></i>
								</a>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</template>
	</main>
</template>

<script>
import VOverlay from '@/components/VOverlay.vue';
import axios from 'axios';

export default {
	name: 'KYC',

	components: { VOverlay },

	data() {
		return {
			loading: false,

			vtolOK: false,

			confirmCustomers: [],

			overlayLoading: false,

			data: [],

			agreements: [],
		};
	},

	computed: {
		incompleteAgreements() {
			return this.agreements.filter((agreement) => !agreement.sentToSwedbankAt);
		},

		completedAgreements() {
			return this.agreements.filter((agreement) => !!agreement.sentToSwedbankAt);
		},
	},

	async mounted() {
		await this.getAgreements();
	},

	methods: {
		getAgreementLink(agreement) {
			if (!agreement?.agreementLink?.token) {
				return '';
			}

			return `${process.env.VUE_APP_TERMINAL_CLIENT_URL}/client?token=${agreement?.agreementLink?.token}`;
		},

		getCustomerVTal(agreement) {
			return agreement?.customer?.vtal;
		},

		getCustomerName(agreement) {
			return agreement?.customer?.name;
		},

		getSteps(agreement) {
			const steps = agreement?.agreementSteps;

			if (!steps) {
				return [0, 0];
			}

			const doneSteps = steps.filter((step) => step.done);

			return [doneSteps.length, steps.length];
		},

		getUpdatedAt(agreement) {
			return new Date(agreement.updatedAt).format('%d/%m/%y %H:%M');
		},

		fileUploaded(e) {
			const reader = new FileReader();

			// read the file as text
			reader.readAsText(e.target.files[0]);

			reader.addEventListener('load', (e) => {
				const text = e.target.result;

				// Split text into lines.
				// Only include lines that have content.
				const lines = text.split('\n').filter((line) => line);

				// Get [vtal, kycId] from .csv file.
				const match = lines[0].match(/\d+([,|;]+).+/);

				if (!match?.length) {
					return;
				}

				const delimiter = match[1];

				this.data = lines.map((line) => line.split(delimiter).map((entry) => entry.trim()));
			});
		},

		async getAgreements() {
			if (this.loading) {
				return;
			}

			this.loading = true;

			await axios
				.get('/agreements/kyc')
				.then((response) => {
					this.agreements = response.data;
				})
				.catch((error) => {
					console.log('Error fetching agreements in KYC', error);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async checkVtol() {
			if (this.overlayLoading) {
				return;
			}

			this.overlayLoading = true;

			await axios
				.post('/agreements/prepare-kyc-list', {
					vtol: this.data.map((entry) => entry[0]),
				})
				.then((response) => {
					this.confirmCustomers = response.data;

					this.vtolOK = true;

					for (const customer of response.data) {
						if (!customer) {
							this.vtolOK = false;
						}
					}
				})
				.catch((error) => {
					console.log('Error preparing customer list', error);
				})
				.finally(() => {
					this.overlayLoading = false;
				});
		},

		async confirmVtol() {
			if (this.overlayLoading) {
				return;
			}

			this.overlayLoading = true;

			await axios
				.post('/agreements/bulk-create-kyc', {
					entries: this.data,
				})
				.then((response) => {
					this.agreements = [...this.agreements, ...response.data];
				})
				.catch((error) => {
					console.log('Error bulk creating KYC agreements', error);
				})
				.finally(() => {
					this.overlayLoading = false;

					this.data = [];
					this.confirmCustomers = [];
				});
		},

		removeVtalAtIndex(index) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.data.splice(index, 1);
		},

		removeCustomerAtIndex(index) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.confirmCustomers.splice(index, 1);
			this.data.splice(index, 1);
		},

		isInactive(agreement) {
			if (!agreement.updatedAt) {
				return true;
			}

			const timeSince = Date.now() - new Date(agreement.updatedAt).getTime();
			return timeSince > 1000 * 60 * 60 * 48;
		},

		isApproved(agreement) {
			return agreement.approved;
		},

		isSentForApproval(agreement) {
			return agreement.sentForApproval;
		},

		hasBankVerification(agreement) {
			return agreement.files.some((file) => file.name.includes('bankaváttan'));
		},

		hasSignature(agreement) {
			return agreement.files.some((file) => file.original_name.includes('-signed.pdf'));
		},

		toggleSentToSwedbank(agreement) {
			axios
				.patch(`/agreements/${agreement.id}/kyc-toggle-sent`)
				.then(() => {})
				.catch(() => {});
		},

		archiveAgreement(agreement) {
			if (!confirm('Ert tú vís/ur í, at tú ynskir at arkivera avtaluna?')) {
				return;
			}

			agreement.isArchived = true;

			axios
				.post(`/agreements/${agreement.id}/set-archived`, {
					archived: true,
				})
				.catch(() => {
					//
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.vtal {
	position: relative;

	display: block;

	margin-bottom: 2px;
	padding: 12px 16px;

	border: 1px solid #f4f4f4;
	border-radius: 4px;

	.fa-trash-alt {
		position: absolute;

		top: 50%;
		right: 10px;
		transform: translateY(-50%);
	}

	.line-through {
		text-decoration: line-through;
	}
}
</style>
